/* Custom Cart Section */
.custom-cart {
    padding: 120px 20px;
    margin: auto;
}

/* Heading Style */
.custom-cart h3 {
    font-size: 26px;
    font-weight: bold;
    color: #333;
}

/* Paragraph Style */
.custom-cart p {
    font-size: 18px;
    font-weight: 500;
    color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
    .custom-cart {
        padding: 80px 20px;
    }

    .custom-cart h3 {
        font-size: 22px;
    }

    .custom-cart p {
        font-size: 16px;
    }
}

@media (max-width: 576px) {
    .custom-cart {
        padding: 60px 15px;
    }

    .custom-cart h3 {
        font-size: 20px;
    }

    .custom-cart p {
        font-size: 15px;
    }
}
