/* Main Product Section */
.custom-product-section {
    padding: 40px 5%;
    margin: 0 auto;
    max-width: 1200px; /* Ensuring same width */
}

/* Product Grid */
.product-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Distribute items evenly */
    gap: 30px; /* Adds consistent spacing */
}

/* Product Item Column (Ensures 3 per row in large screens) */
.product-item > div {
    width: calc(33.33% - 20px); /* 3 items per row with spacing */
    display: flex;
    justify-content: center;
}

/* Center the bottom text */
.custom-product-bottom {
    text-align: center;
    padding: 30px;
    max-width: 800px;
    margin: auto;
}

/* Styling for Product Description */
.custom-product-bottom p {
    font-size: 17px;
    color: #6f7982;
    line-height: 1.6;
}

/* Read More Button */
.custom-product-btn {
    border: none;
    background-color: #2196f3;
    color: white;
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    margin-top: 10px;
}

/* Button Hover Effect */
.custom-product-btn:hover {
    background-color: #0d8bf2;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
    .custom-product-section {
        padding: 30px 3%;
    }

    .product-item {
        justify-content: center;
        gap: 20px;
    }

    /* 2 items per row for tablets */
    .product-item > div {
        width: calc(50% - 15px);
    }
}

@media (max-width: 768px) {
    .custom-product-section {
        padding: 20px;
    }

    .custom-product-bottom {
        padding: 20px;
    }

    .product-item {
        gap: 15px;
    }

    /* 1 item per row for mobile */
    .product-item > div {
        width: 100%;
    }
}
