/* ===========================
   Wikipedia Page Main Styles
   =========================== */

   .wiki-container {
    font-family: Arial, sans-serif;
    max-width: 900px;
    margin: 0 150px; /* Keep this margin */
    padding: 20px;
    background-color: #f8f9fa;
    color: #333;
    position: relative;
}

/* ===========================
   Header Section
   =========================== */
.wiki-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 10px;
    border-bottom: 2px solid #ddd;
}

.wiki-header .wiki-header-top {
    display: grid;
    gap: 15px;
}

.wiki-header .wiki-header-top .wiki-heading {
    font-size: 33px;
    font-weight: bold;
    color: #222;
}

/* Page Title */
.wiki-title {
    font-size: 24px;
    font-weight: bold;
    color: #222;
}

/* ===========================
   Search Box (Position Adjusted)
   =========================== */
.wiki-search-container {
    position: absolute;
    top: 20px;
    right: -300px;
    z-index: 100;
    display: flex;
    align-items: center;
}

@media (max-width: 1024px) {
    .wiki-container {
        margin: 0 50px; /* Reduce side margins */
    }
    .wiki-search-container {
        position: relative;
        right: 0;
        top: 0;
        margin-left: auto;
    }
}

/* Search Input Box */
.wiki-search-input-container {
    display: flex;
    align-items: center;
}

.wiki-search-input-container input {
    border: none;
    outline: none;
    font-size: 14px;
    padding: 5px;
    width: 200px;
    border-bottom: 2px solid #007bff;
}

/* ===========================
   Table of Contents
   =========================== */
.wiki-card {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-top: 20px;
    width: 100%;
    max-width: 600px;
}

.wiki-subtitle {
    font-size: 18px;
    color: gray;
}

.wiki-list {
    list-style: none;
    padding: 0;
}

.wiki-list li {
    padding: 8px 0;
    cursor: pointer;
    color: #007bff;
    transition: color 0.3s ease;
}

.wiki-list li:hover {
    color: #0056b3;
}

/* ===========================
   Section Styles
   =========================== */
.wiki-section {
    margin-top: 50px;
}

.wiki-section-title {
    font-size: 25px;
    font-weight: 700;
    color: #222;
    margin-bottom: 2px;
}

.wiki-text {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
}

/* ===========================
   Responsive Styles
   =========================== */
@media (max-width: 992px) {
    .wiki-search-container {
        display: none;
    }
}

@media (max-width: 768px) {
    .wiki-container {
        margin: 0 20px; /* Further reduce margins */
        padding: 15px;
    }
    .wiki-header {
        flex-direction: column;
        align-items: flex-start;
    }
   
    .wiki-title {
        font-size: 20px;
    }
    .wiki-section-title {
        font-size: 20px;
    }
    .wiki-text {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .wiki-container {
        margin: 0 10px; /* Smallest margin for very small screens */
        padding: 10px;
    }
    .wiki-header {
        text-align: center;
    }
    .wiki-title {
        font-size: 18px;
    }
    .wiki-section-title {
        font-size: 16px;
    }
    .wiki-text {
        font-size: 13px;
        line-height: 1.5;
    }
    .wiki-card {
        padding: 10px;
    }
}