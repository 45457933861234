/* Portfolio Page Container */
.portfolio-page-container {
    padding: 20px;
    max-width: 1300px;
    margin: auto;
}

/* Filter Buttons */
.portfolio-filter-btn {
    background-color: transparent;
    border: none;
    font-size: 18px;
    padding: 8px 16px;
    margin: 5px;
    transition: color 0.3s ease-in-out;
}

.portfolio-filter-btn:hover,
.portfolio-filter-btn.active {
    color: #1e73be;
    font-weight: bold;
}

/* Portfolio Grid Adjustments */
.portfolio-card {
    margin: 0 auto;
}

/* Ensure Portfolio Items are Centered */
.portfolio-card-content {
    display: flex;
    justify-content: center;
}

/* Portfolio Item Hover Effect */
.portfolio-item-wrapper {
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.portfolio-item-wrapper:hover {
    transform: scale(1.05);
}

/* Modal Styling */
.portfolio-modal .modal-content {
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.portfolio-modal-img {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 8px;
}

/* Responsive Spacing */
@media (max-width: 1024px) {
    .portfolio-page-container {
        padding: 15px;
    }
}

@media (max-width: 768px) {
    .portfolio-page-container {
        padding: 10px;
    }
}

@media (max-width: 576px) {
    .portfolio-filter-btn {
        font-size: 16px;
        padding: 6px 12px;
    }
}
