/* Contact Form Main Container */
.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background: #f5f5f5;
  padding: 40px 0;
  margin-top: -90px;
}

/* Contact Box */
.contact-box {
  background: rgba(255, 255, 255, 0.85); /* Glassmorphism effect */
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 30px;
  max-width: 450px;
  width: 100%;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  text-align: center;
}

/* Contact Title & Subtitle */
.contact-title {
  font-size: 24px;
  font-weight: bold;
  color: #222;
  margin-bottom: 10px;
}

.contact-subtitle {
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
}

/* Form Elements */
.form-label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-top: 10px;
  text-align: left;
}

.form-input, .form-textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  outline: none;
  transition: 0.3s;
}

.form-input:focus, .form-textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

.form-textarea {
  height: 100px;
  resize: none;
}

/* Submit Button */
.form-button {
  width: 100%;
  padding: 12px;
  margin-top: 15px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
}

.form-button:hover {
  background: #0056b3;
}

/* Status Message */
.form-status {
  font-size: 14px;
  margin-top: 10px;
  color: green;
}
