/* Main About Container */
.about-container {
  width: 100%;
}

/* About Top Section */
.about-top-content {
  gap: 40px; /* Added space between the two divs */
}

.about-top-content > div {
  margin: 10px 0; /* Ensures consistent spacing on all screens */
}

/* Text Content */
.about-top h5 {
  font-size: 22px;
  font-weight: bold;
  color: #333;
}

.about-top p {
  font-size: 18px;
  color: gray;
  line-height: 1.8;
}

/* About Bottom Image */
.about-bottom img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .about-top {
    padding: 0 40px;
  }
}

@media (max-width: 768px) {
  .about-top h5 {
    font-size: 20px;
  }
  .about-top p {
    font-size: 16px;
  }
  .about-top-content {
    flex-direction: column; /* Stacking on small screens */
    gap: 30px; /* Adjusted spacing for mobile */
  }
}

@media (max-width: 480px) {
  .about-top h5 {
    font-size: 18px;
  }
  .about-top p {
    font-size: 14px;
  }
}
