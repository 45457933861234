.services-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 25px 10px;
    padding: 0 15px;
    box-sizing: border-box;
    margin-bottom: 30px;
}

/* Default width - 3 columns */
.services-content > div {
    width: calc(33.333% - 10px);
}

/* Responsive: 2 per row on tablets */
@media (max-width: 992px) {
    .services-content {
        justify-content: center; /* Center items on tablet */
    }

    .services-content > div {
        width: calc(50% - 10px);
    }
}

/* Responsive: 1 per row on mobile */
@media (max-width: 576px) {
    .services-content {
        padding: 0 20px;
        justify-content: center; /* Center align all items */
    }

    .services-content > div {
        width: 90%; /* Slightly reduce width for better UI */
        max-width: 300px; /* Prevents it from becoming too wide */
        margin-right: 40px;
    }
}
