/* Header Styles */
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    background-color: #ffffff;
    color: #1e73be;
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    height: 80px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

/* Push content down to prevent overlap */
body {
    padding-top: 80px;
}

/* Logo Styling */
.header-logo img {
    width: 180px;
}

/* Navigation List */
.header-list ul {
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 0;
}

.header-list li {
    list-style: none;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    transition: color 0.3s ease-in-out;
    padding: 5px 0;
}

header a {
    text-decoration: none;
    color: #1e73be;
}

/* `::after` effect for all list items except dropdown */
.header-list li:not(.dropdown, .icon1)::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #1e73be;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.header-list li:not(.dropdown, .icon1):hover::after {
    opacity: 1;
}

.dropdown .dropdown-menu li:hover::after{
    opacity: 0;
}

/* Dropdown Styling */
.dropdown {
    position: relative;
    cursor: pointer;
}

/* Dropdown Icon */
.icon {
    margin-left: 5px;
    display: flex;
    align-items: center;
}

/* Dropdown Menu */
/* Ensure Dropdowns Open at the Same Top Position */
.cart-dropdown,
.dropdown .dropdown-menu,
.search-dropdown {
    position: absolute;
    top: 50px; /* Ensures both open from the same top position */
    right: 0;
    width: 260px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
    padding: 15px;
    text-align: left;
    border-top: 4px solid #1e73be;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* Ensure Both Dropdowns Show on Hover */
.cart-container:hover .cart-dropdown,
.dropdown:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.dropdown .dropdown-menu li {
    display: block;
    padding: 10px 15px;
    transition: 0.3s ease-in-out;
}

.dropdown .dropdown-menu li:hover {
    background: #f2f2f2;
}


/* Cart Container */
.cart-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}



/* Show Cart Dropdown on Hover */
.cart-container:hover .cart-dropdown {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

/* Empty Cart Message */
.cart-dropdown p {
    font-size: 14px;
    color: #777;
    margin-bottom: 15px;
}

/* View Cart Button */
.cart-btn {
    background: #00c851;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 600;
    transition: background 0.3s ease-in-out;
}

.cart-btn:hover {
    background: #007e33;
}

/* Subtotal Styling */
.cart-total {
    font-size: 14px;
    color: #333;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    margin-top: -30px;
}

/* Search Box Hover Effect */
.search-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* Search Box Dropdown */


/* Show Search Box on Hover */
.search-container:hover .search-dropdown {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

/* Input Field */
.search-input-container {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 5px;
}

.search-input-container input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 5px;
}

/* Search Icon */
.search-icon {
    color: #666;
}




/* Open Dropdown on Hover (Desktop) */
@media (min-width: 992px) {
    .dropdown:hover .dropdown-menu {
        display: block;
    }
}

/* Mobile Menu Styling */
.mobile-menu {
    display: none;
    cursor: pointer;
    font-size: 28px;
    color: #1e73be;
}

/* Close Menu */
.close-menu {
    position: absolute;
    top: -50px;
    right: 15px;
    font-size: 30px;
    cursor: pointer;
    color: #1e73be;
}


/* Hide Icons in Mobile View */
@media (max-width: 992px) {
    .header-logo img {
        width: 150px;
    }

    .header-list {
        display: none;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: #ffffff;
        padding: 20px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        flex-direction: column;
        gap: 15px;
        z-index: 2;
    }

    .mobile-menu {
        display: block;
    }

    .header-list.active {
        display: flex;
    }

    .header-list ul {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        width: 100%;
    }

    .header-list li {
        font-size: 16px;
       
    }

    /* Hide Icons in Mobile */
    .icon1 {
        display: none;
    }

    /* Mobile Dropdown Works on Click */
    .dropdown .dropdown-menu {
        position: relative;
        width: 100%;
        display: none;
        box-shadow: none;
        background: white;
        border: none;
    }

    .dropdown.open .dropdown-menu {
        display: block;
        margin-top: -50px;
    }
}
