.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 45px; /* Slightly bigger for better touch usability */
    height: 45px;
    background-color: rgba(128, 128, 128, 0.8); /* Semi-transparent grey */
    color: white;
    border-radius: 10px;
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 24px; /* Increased for better visibility */
    cursor: pointer;
    transition: background-color 0.3s ease, opacity 0.3s ease-in-out;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Ensures it stays above other elements */
}

.back-to-top.show {
    display: flex;
}

.back-to-top:hover {
    background-color: rgba(100, 100, 100, 1); /* Darker grey on hover */
}

/*  Responsive Adjustments */
@media (max-width: 768px) {
    .back-to-top {
        bottom: 15px;
        right: 15px;
        width: 40px;
        height: 40px;
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .back-to-top {
        width: 35px;
        height: 35px;
        font-size: 18px;
    }
}
