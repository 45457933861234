/* Business Section */
.business {
    background-color: #f3f8fb;
    padding: 100px 0px; /* Reduced side padding for better responsiveness */
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content vertically */
    text-align: center; /* Center text horizontally */
  }
  
  .business h5 {
    font-size: 30px;
    color: #009fef;
    margin-bottom: 20px;
  }
  
  .business p {
    font-size: 20px;
    color: gray;
    max-width: 600px;
    width: 100%;
    margin: 0 auto; /* Ensure the text is centered */
  }
  
  .business button {
    border: none;
    padding: 12px 50px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    color: #009fef;
    background-color: rgba(33, 150, 243, .15);
    margin-top: 20px; /* Add space between paragraph and button */
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* Button Hover Effect */
  .business button:hover {
    background-color: #2196f3;
    color: white;
  }
  
  /* Desktop Layout */
  @media (min-width: 1025px) {
    .business {
      flex-direction: row; /* Arrange elements horizontally on desktop */
      justify-content: space-between; /* Space between content and button */
      text-align: left; /* Left-align text on large screens */
      padding: 100px 50px; /* Increased side padding for larger screens */
    }
  
    .business p {
      font-size: 20px;
    }
  
    .business button {
      font-size: 16px;
    }
  }
  
  /* Tablet Layout */
  @media (max-width: 1024px) {
    .business h5 {
      font-size: 28px;
    }
  
    .business p {
      font-size: 18px;
    }
  
    .business button {
      padding: 12px 40px;
      font-size: 14px;
    }
  }
  
  /* Mobile Layout */
  @media (max-width: 768px) {
    .business h5 {
      font-size: 24px;
    }
  
    .business p {
      font-size: 16px;
      width: 90%;
    }
  
    .business button {
      padding: 12px 30px;
      font-size: 13px;
    }
  }
  
  /* Extra Small Mobile Layout */
  @media (max-width: 480px) {
    .business h5 {
      font-size: 20px;
    }
  
    .business p {
      font-size: 14px;
      width: 100%;
    }
  
    .business button {
      padding: 12px 25px;
      font-size: 12px;
    }
  }
  