/* Main Container */
.portItem-container {
    position: relative;
    overflow: hidden; 
    width: 100%; 
    max-width: 400px; /* Keep a good max width */
    margin: auto; 
    border-radius: 10px;
}

/* Image Styling */
.portItem-container img {
    width: 100%; 
    height: auto;
    border-radius: 10px;
    transition: opacity 0.4s ease, transform 0.3s ease;
}

/* Hover Effect */
.portItem-container:hover img {
    opacity: 0.3; /* Fade effect */
    transform: scale(1.05);
}

/* Content Overlay */
.portItem-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: rgba(43, 219, 158, 0.85); /* Slightly dark for readability */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

/* Show on hover */
.portItem-container:hover .portItem-content {
    opacity: 1;
}

/* Text Styling */
.portItem-content h3 {
    font-size: 22px;
    color: white;
    margin: 0;
    font-weight: bold;
}

.portItem-content p {
    font-size: 16px;
    color: white;
    font-weight: 500;
    margin-top: 10px;
}

/* Responsive Breakpoints */
@media (max-width: 1024px) {
    .portItem-container {
        max-width: 350px; /* Adjust for tablets */
    }
}

@media (max-width: 768px) {
    .portItem-container {
        max-width: 300px; /* Adjust for mobile */
    }

    .portItem-content {
        padding: 15px;
    }

    .portItem-content h3 {
        font-size: 18px; 
    }

    .portItem-content p {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .portItem-container {
        max-width: 100%;
    }

    .portItem-content {
        padding: 10px;
    }

    .portItem-content h3 {
        font-size: 16px;
    }

    .portItem-content p {
        font-size: 12px;
    }
}
