/* Custom Owner Container */
.custom-owner {
    text-align: center;
    padding: 40px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin: 0 auto 30px auto;
    max-width: 90%;
}



/* Heading Styles */
.custom-owner h3 {
    font-size: 24px;
    color: #333;
    font-weight: bold;
}

/* Paragraph Styles */
.custom-owner p {
    font-size: 17px;
    font-weight: 500;
    color: #555;
}

/* Owner Info */
.owner-info {
    margin-top: 15px;
}

/* Star Rating */
.owner-stars {
    color: #f3bb00;
    font-size: 22px;
}


/* Responsive Design */
@media (max-width: 992px) {
    .custom-owner {
        padding: 30px;
    }
}

@media (max-width: 768px) {
    .custom-owner {
        padding: 25px;
        max-width: 95%;
    }

    .custom-owner h3 {
        font-size: 20px;
    }

    .custom-owner p {
        font-size: 15px;
    }

    .owner-stars {
        font-size: 18px;
    }
}

@media (max-width: 576px) {
    .custom-owner {
        padding: 20px;
        max-width: 98%;
    }

    .custom-owner h3 {
        font-size: 18px;
    }

    .custom-owner p {
        font-size: 14px;
    }
}
