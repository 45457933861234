/* Main Service Container */
.service-container {
  width: 100%;
  overflow: hidden;
}

/* Service Top Section */
.service-top {
  background-image: url("../../assets/page-title-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh; /* Reduced from 60vh */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  position: relative;
}

/* Service Title */
.service-top h3 {
  font-size: 36px; /* Reduced for better desktop view */
  font-weight: 600;
  color: white;
  margin-bottom: 15px;
}

/* Service Description */
.service-top p {
  font-size: 18px; /* Slightly smaller for better readability */
  font-weight: 500;
  color: #baccea;
  max-width: 900px;
  margin: 0 auto;
  line-height: 1.5;
}

/* Decorative Line Under Title */
.service-top::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 3px;
  background-color: #1e73be;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}

/* Service Bottom Section */
.service-bottom {
  text-align: center;
  padding: 30px;
}

.service-bottom h3 {
  font-size: 22px; /* Reduced size for better desktop fit */
  color: #333;
  max-width: 800px;
  margin: 30px auto 0;
}

/*  Responsive Design */

/* Large Screens & Laptops */
@media (max-width: 1200px) {
  .service-top {
    height: 45vh; /* Adjusted height */
  }
  .service-top h3 {
    font-size: 32px;
  }
  .service-top p {
    font-size: 16px;
  }
  .service-bottom h3 {
    font-size: 20px;
  }
}

/* Tablets */
@media (max-width: 768px) {
  .service-top {
    height: 40vh;
    padding: 15px;
  }
  .service-top h3 {
    font-size: 28px;
  }
  .service-top p {
    font-size: 16px;
  }
  .service-bottom h3 {
    font-size: 18px;
  }
}

/* Mobile Devices */
@media (max-width: 480px) {
  .service-top {
    height: 35vh;
    padding: 10px;
  }
  .service-top h3 {
    font-size: 24px;
  }
  .service-top p {
    font-size: 14px;
  }
  .service-bottom h3 {
    font-size: 16px;
  }
}
