/* Container styling */
.wikipedia-content {
    font-family: Arial, sans-serif;
    max-width: 800px;
    line-height: 1.8;
    color: gray;
    margin-top: 15px;
    font-weight: 500;
}

/* Heading */
.wikipedia-content h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
}

/* Paragraphs */
.wikipedia-content p {
    font-size: 16px;
    margin-bottom: 15px;
}

/* List styling */
.wikipedia-content ul {
    list-style-type: disc;
    margin-left: 20px;
}

.wikipedia-content li {
    font-size: 16px;
    margin-bottom: 10px;
}
