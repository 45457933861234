/* Terms of Use Container */
.term-container {
    width: 100%;
    text-align: left;
}

/* Terms Content */
.term-container .term-content {
    padding: 0 clamp(20px, 8vw, 150px); /* Adaptive padding */
    font-size: 16px;
    line-height: 1.6; /* Improved readability */
    color: #333;
    font-weight: 400;
    margin-bottom: 50px;
}

/*  Responsive Design */
@media (max-width: 1024px) {
    .term-container .term-content {
        padding: 0 60px; /* Reduced padding for tablets */
        font-size: 15px;
    }
}

@media (max-width: 768px) {
    .term-container .term-content {
        padding: 0 30px; /* More compact for smaller screens */
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .term-container .term-content {
        padding: 0 15px; /* Minimal padding for mobile */
        font-size: 13px;
        line-height: 1.5;
    }
}
