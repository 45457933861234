/* Custom Brand Section */
.custom-brand {
    padding: 40px 0;
    margin-bottom: 20px;
}

/* Brand Logo Styling */
.custom-brand-img {
    width: 120px;  /* Default width */
    height: auto;  /* Maintain aspect ratio */
    object-fit: contain;
    max-width: 100%;
}

/* Responsive Adjustments */
@media (max-width: 992px) {
    .custom-brand-img {
        width: 100px;
    }
}

@media (max-width: 768px) {
    .custom-brand-img {
        width: 80px;
    }
}
