/* Product Item Styles */
.custom-product-item {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    width: 100%;
    max-width: 350px; /* Fixed width for uniformity */
    height: 100%; /* Ensures equal height */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensures equal spacing */
}

.custom-product-item:hover {
    transform: translateY(-5px);
}

/* Title Styling */
.custom-product-title {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

/* Image Styling */
.custom-product-img {
    width: 100%;
    max-width: 200px;
    height: auto;
    border-radius: 5px;
}

/* Description Styling */
.custom-product-description {
    font-size: 16px;
    color: #6f7982;
    margin-top: 10px;
    flex-grow: 1; /* Ensures text is evenly spaced */
}
