/* Main Application Section */
.custom-application {
    padding: 40px 5%;
    margin: 0 auto;
    max-width: 1200px; /* Ensuring same width */
}

/* Service Grid */
.application-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Distribute items evenly */
    gap: 30px; /* Adds consistent spacing */
}

/* Service Item Column (Ensures 3 per row in large screens) */
.application-item > div {
    width: calc(33.33% - 20px); /* 3 items per row with spacing */
    display: flex;
    justify-content: center;
}

/* Service Card Styles */
.custom-service-item {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    width: 100%;
    max-width: 350px; /* Fixed width */
    height: 100%; /* Ensures equal height */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.custom-service-item:hover {
    transform: translateY(-5px);
}

/* Image Styling */
.custom-service-img {
    width: 100%;
    max-width: 200px;
    height: auto;
    border-radius: 5px;
}

/* Description Styling */
.custom-service-description {
    font-size: 16px;
    color: #6f7982;
    margin-top: 10px;
    flex-grow: 1; /* Ensures text fills available space */
}

/* Responsive Design */
@media (max-width: 1024px) {
    .custom-application {
        padding: 30px 3%;
    }

    .application-item {
        justify-content: center;
        gap: 20px;
    }

    /* 2 items per row for tablets */
    .application-item > div {
        width: calc(50% - 15px);
    }
}

@media (max-width: 768px) {
    .custom-application {
        padding: 20px;
    }

    .application-item {
        gap: 15px;
    }

    /* 1 item per row for mobile */
    .application-item > div {
        width: 100%;
    }
}
