/* WhatsApp Button */
.whatsapp-container {
    position: fixed;
    bottom: 20px;
    left: 20px; /* Moved to the right of Back to Top button */
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #e4e4e4;
    color: #00000099;
    padding: 10px 15px;
    border-radius: 25px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    transition: background-color 0.3s ease;
    width: fit-content;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Ensure it appears above other elements */
}

.whatsapp-container:hover {
    background-color: #d6d6d6;
}

.whatsapp-icon {
    width: 24px;
    height: 24px;
}

.whatsapp-text {
    padding-top: 2px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .whatsapp-container {
        left: 20px; /* Keep WhatsApp aligned with Back to Top on mobile */
        bottom: 15px;
        padding: 8px 12px;
        font-size: 12px;
    }
    .whatsapp-icon {
        width: 20px;
        height: 20px;
    }
}
