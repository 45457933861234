/* Main Container */
.services-item {
    width: 100%;
    max-width: 350px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    transition: transform 0.3s ease-in-out;
    border-radius: 10px;
}

.services-item:hover {
    transform: scale(1.05);
}

/* Image Styling */
.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.service-img {
    width: 120px;
    max-width: 100%;
    display: block;
    border-radius: 5px;
}

/* Text Styling */
.service-title {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
}

.service-description {
    font-size: 16px;
    color: #6f7982;
    line-height: 1.6;
    text-align: center;
}

/* Responsive Design */
@media (max-width: 576px) {
    .service-title {
        font-size: 18px;
    }

    .service-description {
        font-size: 14px;
    }

    .service-img {
        width: 100px;
    }
    .services-item {
        margin: 0px 20px;
    }
}
