/*  Footer Container */
.footer-container {
    font-family: "Rubik", sans-serif;
}

/*  Footer Top */
.footer-top {
    background-image: url("../../assets/footer-app.png");
    background-position: center;
    background-size: cover;
    height: 30vh;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 100%;
    padding: 0 20px;
}

/*  Left Section */
.footer-left {
    max-width: 300px;
}

.footer-left p {
    font-size: 20px;
    font-variant: small-caps;
    font-weight: 500;
    color: #00da97;
}

.footer-contact-number {
    font-size: 30px;
    color: white;
}

/*  Right Section - Newsletter */
.footer-right {
    max-width: 400px;
}

.footer-right p{
    color: #00da97;
    font-size: 20px;
}

.footer-newsletter {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.footer-input {
    flex: 1;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
}

.footer-input:focus {
    outline: 2px solid #00da97;
}

.footer-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #00da97;
    color: white;
    font-size: 14px;
    font-weight: bold;
}

/*  Footer Bottom */
.footer-bottom {
    background-color: #f6f8fc;
    color: #8a99c0;
    padding: 20px 0;
    text-align: center;
}

.footer-bottom-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: auto;
    flex-wrap: wrap;
    padding: 0 20px;
}

/*  Footer Links */
.footer-link {
    font-size: 14px;
    color: #8a99c0;
    transition: color 0.3s ease-in-out;
    text-decoration: none;
    margin-left: 5px;
    font-weight: 500;
}

.footer-link:hover {
    color: #1e73be;
}

/*  Social Icons */
.footer-icons {
    display: flex;
    gap: 15px;
}

.footer-icon {
    font-size: 20px;
    color: #8a99c0;
    transition: color 0.3s ease-in-out;
    cursor: pointer;
}

.footer-icon:hover {
    color: #1e73be;
}

/* 🔹 Responsive Design */
@media (max-width: 1024px) {
    .footer-top {
        height: auto;
        padding: 30px 20px;
    }

    .footer-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-newsletter {
        flex-direction: column;
        width: 100%;
    }

    .footer-button {
        width: 100%;
    }

    .footer-bottom-content {
        flex-direction: column;
        text-align: center;
        gap: 10px;
    }
}
