.portfolio-container {
    margin-bottom: 100px;
    margin-top: -70px;
}

/* Navigation styling */
.portfolio-container .portfolio-nav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    gap: 20px; /* Ensures spacing */
    padding: 0;
    list-style: none;
}

.portfolio-container .portfolio-nav li {
    color: #6d6483;
    cursor: pointer;
    position: relative;
    font-size: 16px;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
}

.portfolio-container .portfolio-nav li:hover {
    color: #1e73be; /* Highlight color */
    text-decoration: underline;
}

/* Underline effect */
.portfolio-container .portfolio-nav li::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #1e73be;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.portfolio-container .portfolio-nav li:hover::after {
    opacity: 1;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .portfolio-container .portfolio-nav {
        gap: 15px; /* Reduce spacing */
    }

    .portfolio-container .portfolio-nav li {
        font-size: 15px;
    }
}

@media (max-width: 768px) {
    .portfolio-container .portfolio-nav {
        gap: 12px;
    }

    .portfolio-container .portfolio-nav li {
        font-size: 14px;
    }
}

@media (max-width: 576px) {
    .portfolio-container .portfolio-nav {
        gap: 10px;
    }

    .portfolio-container .portfolio-nav li {
        font-size: 12px;
    }
}
