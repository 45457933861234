/* Privacy Page Styling */
.privacy-container {
    background: #fff;
    padding: 40px 20px;
    margin-top: 30px;
  }
  
  .privacy-title {
    font-size: 32px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .privacy-content {
    font-size: 16px;
    color: #555;
    line-height: 1.8;
  }
  
  .privacy-subtitle {
    font-size: 22px;
    color: #626262;
    margin-top: 20px;
  }
  
  .privacy-content .privacy-description {
    margin-bottom: 15px;
  }

  
  /* Responsive Design */
  @media screen and (max-width: 1024px) {
    .privacy-container {
      padding: 30px 15px;
    }
    
    .privacy-title {
      font-size: 28px;
    }
  
    .privacy-content {
      font-size: 15px;
    }
  
    .privacy-subtitle {
      font-size: 20px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .privacy-container {
      width: 90%;
      padding: 20px;
    }
  
    .privacy-title {
      font-size: 24px;
    }
  
    .privacy-content {
      font-size: 14px;
    }
  
    .privacy-subtitle {
      font-size: 18px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .privacy-container {
      width: 100%;
      padding: 15px;
    }
  
    .privacy-title {
      font-size: 22px;
    }
  
    .privacy-content {
      font-size: 13px;
    }
  
    .privacy-subtitle {
      font-size: 16px;
    }
  }
  